import { APP_BASE_HREF, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { ThemePickerComponent } from '@shared/feature/themes';
import { DropDownItem, HeaderCustomComponent, HeaderDropDownMenu, SideNav, SidenavComponent, SidenavNavbarBottomComponent } from '@shared/ui/layout';
import { RoleEN, UserRoles, hasRoleWithinAdminPolicy, isType } from '@shared/util/code';
import { AppInfoComponent, AppSettingsService, TOOLS_BASE_URLS, ToolsBaseUrls } from '@shared/util/infrastructure';
import { CurrentOrImpersonateUser, ImpersonateService, ImpersonateUser, LastLoginDetailsComponent, UserService } from '@shared/util/user';
import { environment } from '../../environments/environment';

@Component({
  selector: 'pda-portal',
  templateUrl: './portal.component.html',
  standalone: true,
  imports: [
    SidenavComponent,
    HeaderCustomComponent,
    LastLoginDetailsComponent,
    NgIf,
    SidenavNavbarBottomComponent,
    RouterOutlet,
    ThemePickerComponent,
    AppInfoComponent,
  ],
})
export class PortalComponent implements OnInit {
  headerMenu: HeaderDropDownMenu = {
    icon: 'account_circle',
    items: [
      { icon: 'manage_accounts', name: 'Profile', routerLink: 'user-dashboard' },
      { icon: 'logout', name: 'Log out', routerLink: 'auth/logout' },
    ],
  };

  sideNav: SideNav = [
    { name: 'Projects portal', routerLink: '/portal/projects', icon: 'view_list', roles: ['*'] },
    {
      name: 'Internal users',
      routerLink: '/portal/users/internal',
      icon: 'person',
      roles: [UserRoles.Administrator, UserRoles.SurveyAdministrator],
    },
    {
      name: 'External users',
      routerLink: '/portal/users/external',
      icon: 'person_outline',
      roles: [UserRoles.Administrator, UserRoles.SurveyAdministrator, UserRoles.Manager],
    },
    {
      name: 'User dashboard',
      routerLink: '/user-dashboard',
      icon: 'list_alt',
      roles: [UserRoles.Administrator, UserRoles.SurveyAdministrator, UserRoles.Manager],
    },
    {
      name: 'Templates/themes',
      routerLink: '/portal/administration',
      icon: 'construction',
      roles: [UserRoles.Administrator, UserRoles.SurveyAdministrator, UserRoles.Manager],
    },
    {
      name: 'Response overview',
      click: (ctrlKey: boolean) => {
        const url = this.toolsBaseUrls.reportGroups + 'report-groups?portalApiUrl=' + this.appSettings.portalApiUrl;
        if (ctrlKey) {
          window.open(url);
        } else {
          document.location.href = url;
        }
      },
      icon: 'data_exploration',
      roles: [UserRoles.Administrator, UserRoles.SurveyAdministrator, UserRoles.Manager],
    },
    { name: 'Jobs overview', routerLink: '/portal/hangfire', icon: 'directions_run', roles: [UserRoles.Administrator] },
    {
      name: 'API keys',
      routerLink: '/portal/security',
      icon: 'security',
      roles: [UserRoles.Administrator],
    },
    {
      name: 'Helpdesk',
      routerLink: '/portal/helpdesk',
      icon: 'assignment',
      roles: [UserRoles.Administrator, UserRoles.SurveyAdministrator, UserRoles.Manager, UserRoles.Helpdesk],
    },
  ];

  readonly clientVersion = environment.buildNumber;
  readonly roleEn = RoleEN;
  hasRoleWithinAdminPolicy: boolean;
  private readonly loggedInUser: CurrentOrImpersonateUser;
  constructor(
    @Inject(TOOLS_BASE_URLS) private toolsBaseUrls: ToolsBaseUrls,
    @Inject(APP_BASE_HREF) private baseHref: string,
    private cdr: ChangeDetectorRef,
    userService: UserService,
    private appSettings: AppSettingsService,

    private destroyRef: DestroyRef,
    private impersonateService: ImpersonateService,
  ) {
    this.loggedInUser = userService.currentOrImpersonateUser;
    this.hasRoleWithinAdminPolicy = hasRoleWithinAdminPolicy(this.loggedInUser.role);
  }
  ngOnInit(): void {
    this.impersonateService.impersonate$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((name) => {
      this.updateUserMenu(name);
      this.cdr.markForCheck();
    });
  }

  private updateUserMenu(user: ImpersonateUser) {
    this.headerMenu.items = this.headerMenu.items.filter((x) => !isType<DropDownItem>(x, 'name') || x.name !== 'Stop impersonating');
    if (user) {
      this.headerMenu.items.push({
        name: 'Stop impersonating',
        icon: 'disabled_visible',
        click: async () => {
          await this.impersonateService.undoImpersonate();
          window.location.href = this.baseHref;
        },
      });
      this.headerMenu = { ...this.headerMenu };
      this.sideNav = [...this.sideNav];
    }
  }
}
