import { Routes } from '@angular/router';
import { IsLoggedInGuard } from '@shared/feature/auth';
import { PageNotFoundComponent } from '@shared/feature/common';
import { CanAccessPortalGuard } from '@shared/util/infrastructure';
import { PortalComponent } from './portal/portal.component';

export const routes: Routes = [
  { path: '', redirectTo: 'portal', pathMatch: 'full' },
  {
    path: 'portal',
    component: PortalComponent,
    canActivate: [IsLoggedInGuard, CanAccessPortalGuard],
    children: [
      { path: '', redirectTo: 'projects', pathMatch: 'full' },
      { path: 'projects', loadChildren: () => import('@portals/shared/feature/projects').then((m) => m.ROUTES) },
      {
        path: 'users/internal',
        loadChildren: () => import('@portals/shared/feature/users').then((m) => m.ROUTES),
        data: { type: 'projects', hasProjects: true },
      },
      { path: 'users/external', loadChildren: () => import('@portals/shared/feature/users').then((m) => m.ROUTES), data: { type: 'external' } },
      { path: 'administration', loadChildren: () => import('@portals/shared/feature/settings').then((m) => m.ROUTES) },
      { path: 'hangfire', loadChildren: () => import('@portals/shared/feature/hangfire').then((m) => m.ROUTES) },
      { path: 'security', loadChildren: () => import('@portals/shared/feature/security').then((m) => m.ROUTES) },
      { path: 'helpdesk', loadChildren: () => import('@portals/shared/feature/helpdesk').then((m) => m.ROUTES) },
    ],
  },
  { path: 'auth', loadChildren: () => import('@shared/feature/auth').then((m) => m.ROUTES), data: { title: 'Projects Portal' } },
  {
    path: 'user-dashboard',
    loadChildren: () => import('@portals/shared/feature/user-dashboard').then((m) => m.ROUTES),
  },
  { path: '**', component: PageNotFoundComponent },
];
