<lsu-layout-sidenav headerTitle="Projects portal" [headerDropDownMenu]="headerMenu" [sideNav]="sideNav">
  <lsu-layout-header-custom>
    <div class="d-flex align-items-center">
      <lsu-user-last-login-details class="me-2"></lsu-user-last-login-details>
      <lsf-themes-theme-picker *ngIf="hasRoleWithinAdminPolicy"></lsf-themes-theme-picker>
    </div>
  </lsu-layout-header-custom>
  <lsu-layout-sidenav-navbar-bottom>
    <div class="px-2">
      <lsu-infrastructure-app-info [clientVersion]="clientVersion"></lsu-infrastructure-app-info>
    </div>
  </lsu-layout-sidenav-navbar-bottom>

  <router-outlet></router-outlet>
</lsu-layout-sidenav>
